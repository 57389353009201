import React from 'react';
import { Box } from '@mui/material';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPalPayment = ({ viewModel, skuInfo, onSuccess, onFailure }) => {

  const {
    createInnerOrder,
    checkOrder
  } = viewModel

  const initialOptions = {
    clientId: "AaZ-8TjapkcyfW_a5KiLr76OJVEjiYGOGyB-y9u5Y2m3WLu1Keq7wMx_I6QNlXOJ5QSPqo0gHaM4SXYY",
    currency: "USD",
    intent: "capture",
    locale: "en_US"
  };

  const createOrder = async (data, actions) => {
    try {
      console.log(skuInfo);
      const response = await createInnerOrder(skuInfo.skuId);
      console.log(response);
      
      if (!response) {
        throw new Error('No response from createInnerOrder');
      }
      
      return actions.order.create({
        intent: "CAPTURE",
        purchase_units: [{
          reference_id: response.orderId,
          custom_id: response.orderId,
          amount: {
            value: skuInfo.amount, // 订单总金额
          }
        }],
      });
    } catch (err) {
      console.error("创建订单时出错:", err);
      throw err; // 重新抛出错误，让 PayPal 组件处理
    }
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log("details", details);
      const co = checkOrder(data.orderID)
      onSuccess(data.orderID);
    });
  };
  const onError = (err) => {
    // 支付失败或出错的回调逻辑
    onFailure(err);
    console.error("支付失败或出错: ", err);
  };

  return (
    <Box>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </PayPalScriptProvider>
    </Box>
  )
};


export default PayPalPayment;