import React, { useState, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Popover, Paper, InputBase, Avatar, Button, Modal, Divider, Skeleton, Grid2, Card, CardContent, RadioGroup, FormControlLabel, Radio, Input } from '@mui/material';
import { History, WatchLater, AccountCircle, Search, Paid, Logout, ShoppingCart, Info, Lightbulb } from '@mui/icons-material';
import ContentList from './ContentList';
import { GoogleLogin } from '@react-oauth/google';
import { SubscribeAnima } from './Animation'
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import { LocalStorageContext } from '../utils/localStorage'
import { ReactComponent as PaypalIcon } from '../assets/svg/paypal.svg'
import PayPalPayment from './Payment';


const AppBarComponent = ({ viewModel }) => {
  const {
    fetchWaitchHistoryItems,
    fetchWaitchLaterItems,
    fetchUserDetail,
    fetchDramas,
    fetchSkuInfo,
    signWithGoogleSuccess
  } = viewModel;

  const pricePerEpisode = 0.07

  const [anchorEl, setAnchorEl] = useState(null);
  const [watchLaterList, setWatchLaterList] = useState(null);
  const [watchHistoryList, setWatchHistoryList] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [searchResults, setSearchResults] = useState(false);
  const [skuInfo, setSkuInfo] = useState(false);

  const [popoverContent, setPopoverContent] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  // 设置默认选中的值为第二个卡片
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);

  const [searchQuery, setSearchQuery] = useState(""); // 新增状态来存储搜索框的输入值

  const [pointAmount, _setPointAmount] = useState(10);
  const [pointAmountCost, setPointAmountCost] = useState((10 * pricePerEpisode).toFixed(2));

  const [showPaypal, setShowPaypal] = useState(false);

  const searchInputRef = useRef(null);
  const userPopupRef = useRef(null);

  const handlePaymentSuccess = (details) => {
    console.log('支付成功回调', details);
    setShowPaypal(false)
    setShowPaymentDialog(false)
      (async () => {
        const res = fetchUserDetail()
        setUserDetail(res)
      })();
    const fakeEvent = { currentTarget: userPopupRef.current };
    handlePopoverOpen(fakeEvent, 'User')
    // 在这里处理支付成功后的逻辑
  }; // 添加依赖项，如果有依赖可以在这里指定

  const handlePaymentFailure = (err) => {
    console.error('支付失败回调', err);
    // 在这里处理支付失败后的逻辑
  }; // 添加依赖项，如果有依赖可以在这里指定



  const setPointAmount = (amount) => {
    if (amount < 0) {
      amount = 0
    } else if (amount > 999) {
      amount = 999
    }
    _setPointAmount(amount)
    setPointAmountCost((amount * pricePerEpisode).toFixed(2))
  }

  const handlePointAmountChange = (event) => {
    setPointAmount(event.target.value === '' ? 0 : Number(event.target.value));
  };

  function handleBlur(event) {
    const amount = event.target.value;
    if (amount < 0) {
      amount = 0
    } else if (amount > 999) {
      amount = 999
    }
  }


  const handleSelectedPaymentPlanChange = (event) => {
    setSelectedPaymentPlan(event.target.value);
  };

  const userPopoverOpen = async (currentTarget) => {
    const sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      setModalOpen(true);
      return
    }
    const res = await fetchUserDetail();
    setUserDetail(res)
    setAnchorEl(currentTarget);
    setPopoverContent("User");
  }

  const watchHistoryPopoverOpen = async (currentTarget) => {
    const sessionId = Cookies.get('sessionId');
    if (sessionId) {
      const res = fetchWaitchHistoryItems();
      setWatchHistoryList(res)
    } else {
      const item = LocalStorageContext.getItem("watchHistory")
      if (!item) {
        return
      }
      setWatchHistoryList(item)
    }    
    setAnchorEl(currentTarget);
    setPopoverContent("History");
  }

  const watchLaterPopoverOpen = async (currentTarget) => {
    const sessionId = Cookies.get('sessionId');
    if (sessionId) {
      const res = fetchWaitchLaterItems();
      setWatchLaterList(res)
    } else {
      const item = LocalStorageContext.getItem("watchLater")
      if (!item) {
        return
      }
      console.log("watchLaterList", item)
      setWatchLaterList(item)
    }
    setAnchorEl(currentTarget);
    setPopoverContent("Later");
  }

  const handlePopoverOpen = async (event, content) => {
    const currentTarget = event.currentTarget;
    if (content === 'User') {
      userPopoverOpen(currentTarget)
    }else if (content === 'History') {
      watchHistoryPopoverOpen(currentTarget);
    } else if (content === 'Later') {
      watchLaterPopoverOpen(currentTarget)
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };

  const handleLoginSuccess = async (response) => {
    const res = await signWithGoogleSuccess(response.credential)
    if (res && res.sessionId) {
      // 保存 session 到 cookie，设置有效期为 30 天
      Cookies.set('sessionId', res.sessionId, { expires: 30 });
      setModalOpen(false);
    }
  };

  const handleLoginFailure = (response) => {
    console.log('Login Failed:', response);
  };

  const logout = () => {
    Cookies.remove('sessionId')
    setUserDetail(null)
  };

  const credit = async () => {
    const sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      setModalOpen(true);
      return
    }
    const res = await fetchSkuInfo()
    setSkuInfo(res)
    if (Array.isArray(res) && res.length > 0) {
      setSelectedPaymentPlan(res[0].skuId);
    }
    handlePopoverClose()
    setShowPaymentDialog(true)
  }

  // 定义执行搜索的函数
  const performSearch = async (event) => {
    console.log("searchQuery", searchQuery)
    if (!searchQuery) {
      return;
    }
    const res = await fetchDramas(1, 10, searchQuery);
    setSearchResults(res);
    if (event && event.currentTarget) {
      setAnchorEl(event.currentTarget);
    } else if (searchInputRef.current) {
      setAnchorEl(searchInputRef.current);
    }
    setPopoverContent('Search');
  };


  const { showPaymentDialog, setShowPaymentDialog, setCurrentDramaId } = useDrama();

  const watchSelect = (item) => {
    setCurrentDramaId(item.dramaId)
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "black",
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <>
      <AppBar position='relative' sx={{ background: "linear-gradient(to bottom, #0f0f0f, #1c1c1c)" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', color: "white" }}>BingeShort</Typography>

          <Paper>
            <InputBase
              ref={searchInputRef}
              sx={{
                width: 'auto',
                minWidth: 220,
                paddingLeft: 1,
                flex: 1,
                color: 'black',
              }}
              placeholder="Search Drama You Want See"
              inputProps={{ 'aria-label': 'Search Drama You Want See' }}
              value={searchQuery} // 使用状态
              onChange={(e) => setSearchQuery(e.target.value)} // 更新状态
              onKeyUp={(e) => { // 检测回车键
                if (e.key === 'Enter') {
                  e.preventDefault(); // 防止表单提交
                  performSearch(e);
                }
              }}
            />
            <IconButton
              type="button"
              sx={{ color: 'black' }}
              aria-label="search"
              onClick={(e) => performSearch(e)}
            >
              <Search size='medium' />
            </IconButton>
          </Paper>
          {['History', 'Later', 'User'].map((content, index) => (
            <IconButton ref={index === 2 ? userPopupRef : null} key={content} color="inherit" size='large' onClick={(e) => handlePopoverOpen(e, content)}>
              {index === 0 ? <History /> :
                index === 1 ? <WatchLater /> :
                  <AccountCircle />}
            </IconButton>
          ))}
        </Toolbar>
      </AppBar>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'History'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          <ContentList title="Watch History" items={watchHistoryList} onClick={watchSelect} />
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'Later'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          <ContentList title="Watch Later" items={watchLaterList} onClick={watchSelect} />
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'Search'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper>
          {searchResults && searchResults.total > 0 && (
            <>
              <ContentList title="Search Result" items={searchResults.data} onClick={watchSelect} />
              <Divider />
            </>
          )}
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography>Couldn't find the result you wanted? Subscribe to us, and we'll notify you when there's an update.</Typography>
            <Box><SubscribeAnima onClick={() => console.log('Lottie animation clicked!')} /></Box>
          </Box>
        </Paper>
      </Popover>

      <Popover
        open={Boolean(anchorEl) && popoverContent === 'User'}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper sx={{ width: '28vh', padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
            {userDetail && userDetail.email ? (<Avatar {...stringAvatar('Tim Neutkens')} />) : (<Skeleton variant="circular">
              <Avatar />
            </Skeleton>)}
            <Typography variant="body1">{userDetail && userDetail.email ? userDetail.email : <Skeleton />}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Paid sx={{ color: 'gold' }} />
              <Typography sx={{ marginLeft: 1, fontWeight: 'bold' }}>: {userDetail && userDetail.scoreCount ? userDetail.scoreCount : 500}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <IconButton color="inherit" onClick={credit}>
              <ShoppingCart sx={{ fontSize: '22px' }} />
            </IconButton>
            <IconButton color="inherit" onClick={logout}>
              <Logout sx={{ fontSize: '22px' }} />
            </IconButton>
          </Box>
        </Paper>
      </Popover>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h5" align="center">
            Sign In with Google
          </Typography>
          <Box id="modal-modal-description">
            <GoogleLogin

              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
            />
          </Box>

        </Box>
      </Modal>


      <Modal
        open={showPaymentDialog}
        onClose={() => setShowPaymentDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          height: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <Box>
            <Typography variant="h3" align="center" sx={{ fontWeight: "bold" }} gutterBottom>
              Payment Plans
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 1 }}>
              <Lightbulb size='small' sx={{ color: 'primary.main', marginRight: 1 }} />
              <Typography variant="body2" align="center" >
                Watching any episode consumes 1 point, and each point costs $0.07. For the price of just half a movie ticket, you can unlock a drama.
              </Typography>
            </Box>
            <Divider sx={{ marginTop: 1, marginBottom: 4 }} />
            <RadioGroup value={selectedPaymentPlan} onChange={handleSelectedPaymentPlanChange}>
              <Grid2 container spacing={2} justifyContent="center">
                {Array.isArray(skuInfo) && skuInfo.map((item, index) => (
                  <Grid2 size={12 / skuInfo.length} item key={index}>
                    <Card sx={{ width: '100%', height: '100%', position: 'relative' }}>
                      <FormControlLabel
                        value={index}
                        control={<Radio sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'secondary.main',
                          },
                        }} />}
                        sx={{ position: 'absolute', top: 0, right: 0, margin: 1 }}
                      />
                      <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }} gutterBottom>
                          {item.skuName}
                        </Typography>
                        <Typography variant="subtitle2" align="center" gutterBottom sx={{
                          maxWidth: '90%',
                          maxHeight: '30%',
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          wordWrap: 'break-word',
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          textAlign: 'center',
                          margin: '0 auto',
                          flexGrow: 1
                        }}>
                          {item.skuDesc}
                        </Typography>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }} >
                          {item.amount}$
                        </Typography>
                        {item.inputBox && (
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Input
                              value={pointAmount}
                              size="medium"
                              onChange={handlePointAmountChange}
                              onBlur={handleBlur}
                              inputProps={{
                                step: 1,
                                min: 1,
                                max: 999,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                              }}
                              sx={{
                                '& .MuiInputBase-input': {
                                  textAlign: 'center', // 文本居中对齐
                                  paddingLeft: '0', // 移除左内边距
                                  paddingRight: '0', // 移除右内边距
                                  color: 'secondary.main',
                                  fontWeight: 'bold'
                                },
                              }}
                            />
                          </Box>)
                        }
                      </CardContent>

                    </Card>
                  </Grid2>
                ))}
              </Grid2>
            </RadioGroup>

            <Divider sx={{ marginTop: 4, marginBottom: 1 }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 6 }}>
              <Info size='small' sx={{ color: 'secondary.main', marginRight: 1 }} />
              <Typography variant="body2" align="center" >
                100% secure payment method with money back guarantee.
              </Typography>
            </Box>

            <Box sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 'bold',
                }}
                onClick={() => setShowPaypal(true)}
                startIcon={<PaypalIcon style={{ width: 30, height: 30 }} />}
              >
                Pay Now
              </Button>
            </Box>

          </Box>
        </Box>
      </Modal>

      <Modal
        open={showPaypal}
        onClose={() => setShowPaypal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30vh',
          height: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <PayPalPayment
            viewModel={viewModel}
            skuInfo={skuInfo[selectedPaymentPlan]}
            onSuccess={handlePaymentSuccess}
            onFailure={handlePaymentFailure}
          />
        </Box>
      </Modal>

    </>
  );
};

export default AppBarComponent;
