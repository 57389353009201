import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Fab, Button, IconButton } from '@mui/material';
import { ChevronRight, PlayArrow } from '@mui/icons-material';
import { useDrama } from '../contexts/DramaContext';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid2';


const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 2,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
        display: 'none', // 隐藏滚动条
    },
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',  // Firefox
});

const ListSection = (props) => {
    const { listData } = props;

    const scrollRef = useRef(null)

    const handleScroll = () => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            // 每次点击增加的滑动距离
            const scrollIncrement = 300;
            // 计算新的滑动位置
            const newScrollPosition = container.scrollLeft + scrollIncrement;
            // 设置新的滑动位置
            container.scrollLeft = newScrollPosition;
        }
    };

    const { setCurrentDramaId, setSelectedItem ,setDiableFetchDrama} = useDrama();

    const handleWatchNow = (id) => {
        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
            <ScrollContainer ref={scrollRef}>
                {listData.map((item, index) => (
                    <Box key={index} sx={{ flex: 'none', width: '10%', height: '100%', marginRight: index !== listData.length - 1 ? 2 : 0 }}>
                        <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c', position: 'relative' }}>
                            <CardMedia component="img" sx={{
                                width: '100%',
                                height: '90%',
                                objectFit: 'cover'
                            }} image={item.imgUrl} />
                            <CardContent sx={{
                                height: '10%', padding: 0, display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography variant="caption" sx={{
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    color: 'white',
                                    margin: '0 auto'
                                }}>{item.name}
                                </Typography>
                            </CardContent>
                            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                <IconButton size='small' sx={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                    value={item.dramaId}
                                    onClick={() => handleWatchNow(item.dramaId)}
                                >
                                    <PlayArrow fontSize="small" />
                                </IconButton>
                            </Box>
                        </Card>
                    </Box>
                ))}
            </ScrollContainer>
            <FloatingButtons>
                <Fab color="primary" aria-label="like" onClick={handleScroll}><ChevronRight /></Fab>
            </FloatingButtons>
        </Box>)

};

const ShowContent = ({ viewModel }) => {
    const {
        fetchDramas,
    } = viewModel;


    const [listData, setListData] = useState({});
    const [previewData, setPreviewData] = useState({});
    const { setCurrentDramaId, setSelectedItem ,setDiableFetchDrama} = useDrama();

    useEffect(() => {
        (async () => {
            const results = await fetchDramas(1, 20);
            if (results && results.data && results.data.length > 0) {
                let preview = {
                    large: results.data[0] || {},
                    small: results.data.slice(1, 3)
                };
                setPreviewData(preview);
                setListData(results.data.slice(3));
            }
        })();
    }, []);

    const handleWatchNow = (id) => {
        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', height: 'auto', width: '100%', flexDirection: 'column', margin: 2, overflow: 'hidden' }}>
            {previewData && previewData.large && Object.keys(previewData.large).length > 0 && (
                <Grid container spacing={1}>
                    <Grid item size={8}>
                        <Card sx={{ display: 'flex', backgroundColor: '#1c1c1c', position: 'relative', height: '100%' }}>
                            <CardMedia component="img" image={previewData.large.imgUrl} sx={{ width: '30%', height: '100%', objectFit: 'cover' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                <CardContent sx={{ flex: '1', alignItems: 'flex-start', color: 'white', height: '100%' }}>
                                    <Typography variant="h5" sx={{ textAlign: 'left' }}>{previewData.large.name}</Typography>
                                    <Typography variant="caption" sx={{ textAlign: 'left' }}>{previewData.large.dramaDetailCount} Episodes</Typography>
                                    <Typography variant="body1" sx={{
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 10,
                                        maxHeight: '80%',
                                        WebkitBoxOrient: 'vertical',
                                    }}>{previewData.large.desc}</Typography>
                                </CardContent>
                                <Box sx={{ position: 'absolute', bottom: 12, right: 12 }}>
                                    <Button variant="outlined" size='large' startIcon={<PlayArrow />}
                                        value={previewData.large.dramaId}
                                        onClick={(e) => handleWatchNow(e.target.value)}
                                    >
                                        Watch Now
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item size={4} container spacing={1}>
                        {previewData.small.map((item, index) => (
                            <Grid key={index} size={12}>
                                <Card sx={{ display: 'flex', backgroundColor: '#1c1c1c', position: 'relative' }}>
                                    <CardMedia component="img" image={item.imgUrl} sx={{ width: '30%', height: '100%', objectFit: 'cover' }} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%', color: 'white' }}>
                                        <CardContent sx={{ flex: '1', alignItems: 'flex-start' }}>
                                            <Typography variant="h6" sx={{ textAlign: 'left' }}>{item.name}</Typography>
                                            <Typography variant="caption" sx={{ textAlign: 'left' }}>{item.dramaDetailCount} Episodes</Typography>
                                            <Typography variant="body2" sx={{
                                                textAlign: 'left',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                maxHeight: '60%',
                                                WebkitBoxOrient: 'vertical'
                                            }}>{item.desc}</Typography>
                                        </CardContent>
                                    </Box>
                                    <Box sx={{ position: 'absolute', bottom: 12, right: 12 }}>
                                        <Button variant="outlined" size='small' startIcon={<PlayArrow />}
                                            value={item.dramaId}
                                            onClick={(e) => handleWatchNow(e.target.value)}
                                        >
                                            Watch Now
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            <Typography variant="h4" gutterBottom sx={{ color: 'white', marginTop: 2 }}>
                Must-sees
            </Typography>
            {listData && listData.length > 0 && <ListSection listData={listData} />}
        </Box>
    );
};

export default ShowContent;