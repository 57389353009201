import { useState } from 'react';
import api from '../utils/api';
import Cookies from 'js-cookie';

const ViewModel = (useMockData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const parseResponse = (response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText || 'unknow error');
    }
    const data = response.data
    if (data.success === true) {
      return data.data;
    } else if (data.code === -1 && data.msg === "NOT_LOGIN") {
      Cookies.remove('sessionId')
      throw new Error(data.msg || 'signIn expire');
    } else {
      throw new Error(data.msg || 'unknow error');
    }
  };

  const signWithGoogleSuccess = async (token) => {
    try {
      setIsLoading(true);
      const response = await api.get(`/user/auth/google/${token}`);
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };


  const fetchUserDetail = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/user/getUserDetail', { headers });
        const data = parseResponse(response);
        return data
      } catch (err) {
        setError(err.message);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const reportPlayRecord = async (dramaId, ep_id, time) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const body = {
        "dramaDetailId": dramaId,
        "epId": ep_id,
        "time": time
      }
      const response = await api.post('/drama/addWatchHistoryyyy', body, { headers });
      return parseResponse(response);
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const watchLater = async (dramaId, ep_id, time) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const body = {
        "dramaId": dramaId,
        "epId": ep_id,
        "time": time
      }
      const response = await api.post('/drama/watchLater', body, { headers });
      return parseResponse(response);
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };


  const fetchWaitchHistoryItems = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/waitchHistoryItems', { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchWaitchLaterItems = async () => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/waitchLaterItems', { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchDramas = async (pageNo, pageSize, keyword,tag) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        let request = {
          'page': pageNo,
          'pageSize': pageSize
        }
        if (keyword) {
          request['dramaName'] = keyword;
        }
        if(tag){
          request['tag'] = tag;
        }
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.post('/drama/list', request, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return [];
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchEpisodes = async (show_id) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get(`/drama/getDrama/${show_id}`, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(prev => ({ ...prev, episodes: err.message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchEpisodeDetail = async (episode_id) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get(`/drama/getDramaDetail/${episode_id}`, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(prev => ({ ...prev, episodes: err.message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchComments = async (show_id, episodes_id) => {
    if (useMockData) {
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const sessionId = Cookies.get('sessionId');
        const headers = {};

        if (sessionId) {
          headers['Authorization'] = sessionId;
        }
        const response = await api.get('/comments', { show_id, episodes_id }, { headers });
        const data = parseResponse(response);
        return data;
      } catch (err) {
        setError(err.message);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchSkuInfo = async () => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      const response = await api.get('/order/getAllSku', { headers });
      const data = parseResponse(response);
      return data;
    } catch (err) {
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const createInnerOrder = async (skuId) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        'skuId': skuId,
      }
      const response = await api.post('/order/createOrder', request, { headers });
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null
    } finally {
      setIsLoading(false);
    }
  }

  const checkOrder = async (orderId) => {
    try {
      setIsLoading(true);
      const sessionId = Cookies.get('sessionId');
      const headers = {};

      if (sessionId) {
        headers['Authorization'] = sessionId;
      }
      let request = {
        'outOrderId': orderId,
        "outPayTypeEnum": "PAY_PAL"
      }
      const response = await api.post('/order/authOrder', request, { headers });
      const data = parseResponse(response);
      return data
    } catch (err) {
      setError(err.message);
      return null
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    error,
    signWithGoogleSuccess,
    reportPlayRecord,
    watchLater,
    fetchUserDetail,
    fetchWaitchHistoryItems,
    fetchWaitchLaterItems,
    fetchDramas,
    fetchEpisodes,
    fetchEpisodeDetail,
    fetchComments,
    fetchSkuInfo,
    createInnerOrder,
    checkOrder,
    setError,
  };
};

export default ViewModel;
