import React, { createContext, useState, useContext } from 'react';

const DramaContext = createContext();

export const DramaProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState('Hot');
    const [currentDramaId, setCurrentDramaId] = useState(null);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentEpisode, setCurrentEpisode] = useState(null);
    const [currentPlayTime, setCurrentPlayTime] = useState(0);
    const [diableFetchDrama, setDiableFetchDrama] = useState(false);

    return (
        <DramaContext.Provider value={{
            selectedItem,
            setSelectedItem,
            currentDramaId,
            setCurrentDramaId,
            showPaymentDialog,
            setShowPaymentDialog,
            currentPage,
            setCurrentPage,
            currentPlayTime,
            setCurrentPlayTime,
            diableFetchDrama,
            setDiableFetchDrama
        }}>
            {children}
        </DramaContext.Provider>
    );
};

export const useDrama = () => useContext(DramaContext);