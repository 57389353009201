import React from 'react';
import Lottie from 'react-lottie';
import vbAnimationData from '../assets/animations/VideoBuffering.json'; // 导入你的 Lottie 动画文件
import sbAnimationData from '../assets/animations/subscribe.json'; // 导入你的 Lottie 动画文件
import { Box } from '@mui/material';

export const VideoBuffering = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: vbAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={200} width={200} />;
};

export const SubscribeAnima = ({ onClick }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sbAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Box onClick={onClick} style={{ cursor: 'pointer' }}>
    <Lottie options={defaultOptions} height={100} width={200} />
  </Box>
};
