import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Button, TextField, Typography, Slider, Modal, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { PlayArrow, Pause, Loop, Speed, VolumeUp, VolumeOff, PictureInPicture, Fullscreen, Subtitles } from '@mui/icons-material';
import { VideoBuffering } from './Animation';
import { LocalStorageContext } from '../utils/localStorage'
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';


const Subtitle = ({ text, settings }) => (
  <Typography
    sx={{
      position: 'absolute',
      [settings.position]: '10%',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: `${settings.size}px`,
      fontWeight: 'bold',
      color: settings.color,
      backgroundColor: settings.backgroundColor,
      padding: '5 5 5 5',
      textAlign: 'center',
      maxWidth: '80%',
      lineHeight: 1,
      textShadow: '2px 2px 4px rgba(0,0,0)',
    }}
  >
    {text}
  </Typography>
);


const VideoPlayer = ({ viewModel,currentEpisode, ratio = 9 / 16 }) => {

  const containerRef = useRef(null);
  const videoRef = useRef(null);

  const { reportPlayRecord } = viewModel
  const { setCurrentPlayTime } = useDrama();

  const [subtitleLang, setSubtitleLang] = useState('en');
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isLooping, setIsLooping] = useState(false);
  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const [subtitleSettings, setSubtitleSettings] = useState({
    position: 'bottom',
    size: 48,
    color: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0)'
  });
  const [showSubtitleSettings, setShowSubtitleSettings] = useState(false);
  const [parsedSubtitles, setParsedSubtitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reportIntervalRef = useRef(null);


  const reportPlayback = () => {
    // 这里实现向后端发送播放记录的逻辑
    console.log("reportPlayback", videoRef.current.currentTime)
    if (videoRef.current.currentTime >= 10) {
      const sessionId = Cookies.get('sessionId')
      if (sessionId) {
        reportPlayRecord(currentEpisode.dramaId, currentEpisode.id, videoRef.current.currentTime)
      } else {
        currentEpisode['playTime'] = videoRef.current.currentTime
        LocalStorageContext.pushItem("watchHistory", currentEpisode)
      }
    }
  };

  useEffect(() => {
    console.log("useEffect",currentEpisode)
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadstart', handleLoadStart);
      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('waiting', handleWaiting);
      video.addEventListener('play', handlePlay);
      video.addEventListener('pause', handlePause);

    }

    return () => {
      if (video) {
        video.removeEventListener('loadstart', handleLoadStart);
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('waiting', handleWaiting);

        video.removeEventListener('play', handlePlay);
        video.removeEventListener('pause', handlePause);
        if (reportIntervalRef.current) {
          clearInterval(reportIntervalRef.current)
        }
      }
    };
  }, []);

  useEffect(() => {
    setIsPlaying(false)
    if (currentEpisode.subtitles) {
      fetchSubtitles(currentEpisode.subtitles[subtitleLang])
        .then((vttString) => {
          const parsedSubtitles = parseVTT(vttString);
          setParsedSubtitles(parsedSubtitles);
        })
        .catch((error) => {
          console.error('Error in subtitle processing:', error);
        });
    }
  }, [currentEpisode]);


  const fetchSubtitles = async (url) => {
    try {
      const response = await fetch(url,{
        method: 'GET',
        credentials: 'omit', // 不发送凭证
        mode: 'cors', // 明确指定 CORS 模式
      });

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('text/vtt')) {
        console.warn('Unexpected content type:', contentType);
      }

      const vttString = await response.text();
      return vttString;
    } catch (error) {
      console.error('Failed to fetch subtitles:', error);
      throw error;
    }
  };

  const handleLoadStart = () => {
    setIsLoading(true);
  };

  const handleCanPlay = () => {
    setIsLoading(false);
  };

  const handleWaiting = () => {
    setIsLoading(true);
  };

  const handlePlay = () => {
    if (reportIntervalRef.current) {
      clearInterval(reportIntervalRef.current);
    }
    let hasReported = false;
    reportIntervalRef.current = setInterval(() => {
      console.log("handlePlay",videoRef.current.currentTime)
      if (videoRef.current.currentTime >= 10 && !hasReported) {
        reportPlayback();
        hasReported = true;
        clearInterval(reportIntervalRef.current);
      }
    }, 2000);
  };

  const handlePause = () => {
    if (reportIntervalRef.current) {
      clearInterval(reportIntervalRef.current);
      reportIntervalRef.current = null;
    }
  };


  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const width = containerHeight * ratio;
        setVideoSize({ width, height: containerHeight });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [ratio]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, []);

  useEffect(() => {
    const updateSubtitle = () => {
      if (parsedSubtitles && parsedSubtitles.length > 0) {
        const currentTime = videoRef.current.currentTime;
        const subtitle = parsedSubtitles.find(
          (sub) => currentTime >= sub.start && currentTime <= sub.end
        );
        setCurrentSubtitle(subtitle ? subtitle.text.trim() : '');
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', updateSubtitle);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', updateSubtitle);
      }
    };
  }, [parsedSubtitles]);


  const parseVTT = (vttString) => {
    const lines = vttString.split('\n');
    const cues = [];
    let cue = null;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      if (line.includes('-->')) {
        // 时间轴行
        const [start, end] = line.split(' --> ').map((time) => {
          const [hours, minutes, seconds] = time.split(':');
          return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds);
        });
        cue = { start, end, text: '' };
      } else if (cue && line !== '') {
        // 字幕文本行
        cue.text += (cue.text ? '\n' : '') + line;
      } else if (cue && line === '') {
        // 空行，表示一个字幕块结束
        cues.push(cue);
        cue = null;
      }
      // 忽略其他行（如索引行）
    }

    // 处理最后一个字幕块
    if (cue) {
      cues.push(cue);
    }

    return cues;
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    setCurrentPlayTime(currentTime)
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // 在组件顶部添加新的状态
  const [activePopup, setActivePopup] = useState(null);

  // 修改 setShowSpeedOptions 和 setShowVolumeSlider 的调用
  const toggleSpeedOptions = () => {
    if (activePopup === 'speed') {
      setActivePopup(null);
    } else {
      setActivePopup('speed');
    }
  };

  const toggleVolumeSlider = () => {
    if (activePopup === 'volume') {
      setActivePopup(null);
    } else {
      setActivePopup('volume');
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    videoRef.current.volume = newValue;
    setIsMuted(newValue === 0);
  };

  // 修改 handleSpeedChange 函数
  const handleSpeedChange = (newSpeed) => {
    setPlaybackRate(newSpeed);
    videoRef.current.playbackRate = newSpeed;
    toggleSpeedOptions();
  };

  const toggleLoop = () => {
    setIsLooping(!isLooping);
    videoRef.current.loop = !isLooping;
  };

  const toggleSubtitleSettings = () => {
    setShowSubtitleSettings(!showSubtitleSettings);
  };

  const updateSubtitleSettings = (setting, value) => {
    setSubtitleSettings(prev => ({ ...prev, [setting]: value }));
  };

  const enterPictureInPicture = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      } else {
        await videoRef.current.requestPictureInPicture();
      }
    } catch (error) {
      console.error('Picture-in-Picture failed:', error);
    }
  };

  const enterFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <video
        ref={videoRef}
        src={currentEpisode.dramaUrl}
        poster={currentEpisode.imgUrl}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          objectPosition: 'center',
        }}
      />
      {/* {!isPlaying && 
      (<Box sx={{position:'absolute',top:'50%',left:'50%'}}>
        <Typography>dsada</Typography>
        <IconButton sx={{fontSize:68,color:'black'}} onClick={setIsPlaying(true)}>
          <PlayArrow />
        </IconButton>
        </Box>  )
      } */}
      
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <VideoBuffering />
        </Box>
      )}
      <Subtitle text={currentSubtitle} settings={subtitleSettings} />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        borderRadius: '6 6 0 0',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0',
      }}>

        <Slider
          value={currentTime}
          max={duration}
          onChange={(e, newValue) => {
            videoRef.current.currentTime = newValue;
          }}
          sx={{
            width: '100%',
            padding: '0',  // 添加这行
            marginTop: '0',  // 添加这行
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 2, marginBottom: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ color: 'black' }} onClick={togglePlay}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Typography variant="body2" sx={{ marginX: '10px', color: 'black' }}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Share Your Commonts..."
              sx={{ marginLeft: 4, width: "16vw", color: 'black' }}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ color: 'black' }} onClick={toggleLoop}>
              <Loop color={isLooping ? 'primary' : 'inherit'} />
            </IconButton>
            <Box sx={{ position: 'relative' }}>
              <IconButton sx={{ color: 'black' }} onClick={toggleSpeedOptions}>
                <Speed />
              </IconButton>
              {activePopup === 'speed' && (
                <Box sx={{
                  position: 'absolute',
                  bottom: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: '4px',
                  padding: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                  {[0.25, 0.5, 1, 1.25, 1.5, 2].map((speed) => (
                    <Button
                      key={speed}
                      onClick={() => handleSpeedChange(speed)}
                      sx={{
                        color: playbackRate === speed ? 'primary.main' : 'white',
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                        width: '100%',
                        padding: '8px 8px',
                      }}
                    >
                      {speed}x
                    </Button>
                  ))}
                </Box>
              )}
            </Box>

            <Box sx={{ position: 'relative' }}>
              <IconButton sx={{ color: 'black' }} onClick={toggleVolumeSlider}>
                {isMuted ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
              {activePopup === 'volume' && (
                <Box sx={{
                  position: 'absolute',
                  bottom: '100%',
                  height: "26",
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: '4px',
                  padding: '4 2 2 2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                  <Slider
                    value={isMuted ? 0 : volume}
                    onChange={handleVolumeChange}
                    orientation="vertical"
                    min={0}
                    max={1}
                    step={0.1}
                    sx={{
                      height: '100%',
                    }}
                  />
                </Box>
              )}
            </Box>
            <IconButton sx={{ color: 'black' }} onClick={toggleSubtitleSettings}>
              <Subtitles />
            </IconButton>
            <IconButton sx={{ color: 'black' }} onClick={enterPictureInPicture}>
              <PictureInPicture />
            </IconButton>
            <IconButton sx={{ color: 'black' }} onClick={enterFullscreen}>
              <Fullscreen />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Modal
        open={showSubtitleSettings}
        onClose={toggleSubtitleSettings}
        aria-labelledby="subtitle-settings-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="subtitle-settings-modal" variant="h6" component="h2">
            Subtitle Settings
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Position</InputLabel>
            <Select
              value={subtitleSettings.position}
              onChange={(e) => updateSubtitleSettings('position', e.target.value)}
            >
              <MenuItem value="top">Top</MenuItem>
              <MenuItem value="bottom">Bottom</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Size</InputLabel>
            <Select
              value={subtitleSettings.size}
              onChange={(e) => updateSubtitleSettings('size', e.target.value)}
            >
              <MenuItem value={24}>Small</MenuItem>
              <MenuItem value={32}>Medium</MenuItem>
              <MenuItem value={48}>Large</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Color</InputLabel>
            <Select
              value={subtitleSettings.color}
              onChange={(e) => updateSubtitleSettings('color', e.target.value)}
            >
              <MenuItem value="#ffffff">White</MenuItem>
              <MenuItem value="#ffff00">Yellow</MenuItem>
              <MenuItem value="#00ff00">Green</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Background Color</InputLabel>
            <Select
              value={subtitleSettings.backgroundColor}
              onChange={(e) => updateSubtitleSettings('backgroundColor', e.target.value)}
            >
              <MenuItem value="rgba(0, 0, 0, 0.5)">Semi-transparent Black</MenuItem>
              <MenuItem value="rgba(0, 0, 0, 0.8)">Deep Black</MenuItem>
              <MenuItem value="rgba(255, 255, 255, 0.5)">Semi-transparent White</MenuItem>
              <MenuItem value="rgba(0, 0, 0, 0)">Transparent</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={toggleSubtitleSettings} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

    </Box>
  );
};

export default VideoPlayer;
