import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
  background: 'linear-gradient(to top, #0f0f0f, #1c1c1c)'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.9)',
}));

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container>
        <StyledTypography variant="body1" align="center">
          app.bingeshorts.com © {currentYear}
        </StyledTypography>
      </Container>
    </StyledFooter>
  )
};


export default Footer;