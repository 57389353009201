// components/MainContent.jsx
import React from 'react';
import VideoContent from './VideoContent';
import ShowContent from './ShowContent';
// import UpdatesContent from './UpdatesContent';
import CategoryContent from './CategoryContent';
import SubscriptionsContent from './SubscribeContent';
import { useDrama } from '../contexts/DramaContext';

const MainContent = ({viewModel}) => {

  const { selectedItem } = useDrama();

  const renderContent = () => {
    switch (selectedItem) {
      case 'Hot':
        return <VideoContent viewModel = {viewModel}/>;
      case 'Updates':
        return <ShowContent viewModel = {viewModel}/>;
      case 'Subscriptions':
        return <SubscriptionsContent/>;
      case 'Category':
        return <CategoryContent viewModel = {viewModel}/>;
      default:
        return <VideoContent viewModel = {viewModel}/>;
    }
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default MainContent;
