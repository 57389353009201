import React, { useEffect } from 'react';
import { CircularProgress, Alert, Box } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppBarComponent from '../components/AppBarComponent';
import LeftSidebar from '../components/LeftSidebar';
import MainContent from '../components/MainContent';
import Footer from '../components/Footer';
import ViewModel from '../services/ViewModel';


const HomePage = () => {

  const viewModel = ViewModel(false);
  const { isLoading, error,setError } = viewModel;

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <GoogleOAuthProvider clientId="986741823231-brvrqd0rkabl8jrqus1o6a1uj32faofv.apps.googleusercontent.com" sx={{height:"100vh"}}>
      <Box sx={{height:"100vh",width:"100vw",display:"flex",flexDirection:"column",overflow:"hidden",backgroundColor:"#0f0f0f"}}>
        <AppBarComponent
          viewModel={viewModel}
        />

        <Box flex={1} display="flex" position="relative" sx={{ height:'90vh'}}>
          <LeftSidebar sx={{ display: 'flex', height: '100%',flexDirection: 'column', alignItems: 'center', backgroundColor: "#1c1c1c" }} />
          <MainContent viewModel={viewModel} sx={{ display: 'flex', height: '100%',flexDirection: 'column', alignItems: 'center', backgroundColor: "#ffffff" }} />
        </Box>

        <Footer/>

        {isLoading && (
          <Box sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)"}}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{position:"absolute",top:"50%" , left:"50%",transform:"translate(-50%, -50%)"}}>
            <Alert severity="error" onClose={() => setError(false)}>{error}</Alert>
          </Box>
        )}
      </Box>
    </GoogleOAuthProvider>
  );
};

export default HomePage;