export class LocalStorageContext {
    // 获取数据
    static getItem(key) {
        const item = localStorage.getItem(key);
        try {
            return JSON.parse(item);
        } catch (e) {
            console.error('解析失败', e);
            return null;
        }
    }

    // 存储数据
    static setItem(key, value) {
        try {
            const item = JSON.stringify(value);
            localStorage.setItem(key, item);
        } catch (e) {
            console.error('存储失败', e);
        }
    }

    // 存储数据
    static pushItem(key, value) {
        try {
            let items = JSON.parse(localStorage.getItem(key)) || []
            for (const item of items){
                if (item.dramaId === value.dramaId){
                    return
                }
            }
            value['createdAt'] = new Date().toISOString();
            // 将新项添加到数组开头
            items.unshift(value);
            // 如果数组长度超过15，则删除最后一项
            if (items.length > 15) {
                items = items.slice(0, 15);
            }
            localStorage.setItem(key, JSON.stringify(items));
        } catch (e) {
            console.error('存储失败', e);
        }
    }

    // 移除数据
    static removeItem(key) {
        localStorage.removeItem(key);
    }

    // 清空本地存储
    static clear() {
        localStorage.clear();
    }
}
