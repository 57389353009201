import React, { useRef } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Fab } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import styled from '@emotion/styled';

const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: -16,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    height: '100%',
});

const listData = [
    {
        title: "Falling In Love With The Rascal In A Suit",
        img: "https://freelancer0904.s3.ap-northeast-2.amazonaws.com/tyxll.jpg",
        description: "Molly’s sister didn’t just swipe her boyfriend Connor—she also snatched her inheritance, leaving Molly high and dry. Fueled by alcohol and vengeance, Molly hatches a wild plan: seduce Connor’s attractive uncle, Adrian. Little does she know, her half-baked scheme works a little too well, because Adrian soon finds himself head over heels for Molly.",
    },
    {
        title: "The Best Mistake Ever",
        img: "https://cdrama-bucket.s3.us-west-1.amazonaws.com/dev/SilentConcubine/0.jpg",
        description: "Molly’s sister didn’t just swipe her boyfriend Connor—she also snatched her inheritance, leaving Molly high and dry. Fueled by alcohol and vengeance, Molly hatches a wild plan: seduce Connor’s attractive uncle, Adrian. Little does she know, her half-baked scheme works a little too well, because Adrian soon finds himself head over heels for Molly.",

    }
    // Add more items as needed
];
// 创建一个新数组来存储模拟的12个数据
let mockData = [];

// 当mockData的长度小于12时，继续添加数据
while (mockData.length < 20) {
    // 遍历listData并添加到mockData中
    listData.forEach(item => {
        // 检查添加后的长度，确保不超过12
        if (mockData.length < 20) {
            mockData.push({ ...item });
        }
    });
}

const ListSection = () => {

    const scrollRef = useRef(null)

    const handleScroll = () => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            // 每次点击增加的滑动距离
            const scrollIncrement = 300;
            // 计算新的滑动位置
            const newScrollPosition = container.scrollLeft + scrollIncrement;
            console.log(newScrollPosition)
            // 设置新的滑动位置
            container.scrollLeft = newScrollPosition;
        }
    };

    return (
        <Box sx={{ position: 'relative', flex: 3, height: '34%', margin: 3 }}>
            <ScrollContainer ref={scrollRef}>
                {mockData.map((item, index) => (
                    <Box key={index} sx={{ flex: 'none', width: '10%', height: '100%', marginRight: index !== mockData.length - 1 ? 1 : 0 }}>
                        <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c' }}>
                            <CardMedia component="img" sx={{
                                width: '100%',
                                height: '90%',
                                objectFit: 'cover'
                            }} image={item.img} />
                            <CardContent>
                                <Typography variant="body2" sx={{
                                    textAlign: 'left',
                                    height: '10%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    color: 'white'
                                }}>{item.title}</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </ScrollContainer>
            <FloatingButtons>
                <Fab color="primary" aria-label="like" onClick={handleScroll}><ChevronRight /></Fab>
            </FloatingButtons>
        </Box>)

};

const SubscriptionsContent = () => (
    <Box>
        <Typography variant="h4" gutterBottom sx={{ color: 'white', margin: 3 }}>Subscriptions</Typography>
        <ListSection />
    </Box>
);

export default SubscriptionsContent;