import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import { format, parseISO } from 'date-fns';

const ContentList = ({ title, items, onClick }) => {
  const [contentItems, setContentItems] = useState(items);

  useEffect(() => {
    setContentItems(items);
  }, [items]);

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="h4" sx = {{margin:1,fontWeight:'bold'}}>
        {title}
      </Typography>
      <Divider />
      <List>
        {contentItems && contentItems.map((item, index) => (
          <ListItem key={index} alignItems="flex-start" onClick={() => onClick(item)} sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'action.selected',
            },
          }}>
            <ListItemAvatar>
              <Avatar src={item.imgUrl} variant="square" sx={{ width: 96, height: 128 }} />
            </ListItemAvatar>
            <ListItemText
              sx={{
                height:128,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                ml: 2 // 添加左边距
              }}
              primary={item.name}
              primaryTypographyProps={{
                sx: { mb: 1 } // 加粗标题并添加底部边距
              }}
              secondary={
                <Box>
                  <Typography variant="body1" color="textSecondary">
                    Saw {item.order}th Episodes
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{paddingTop:2}}>
                    {format(parseISO(item.createdAt), 'yyyy-MM-dd HH:mm')}
                  </Typography>
                </Box>
              }
            />
          </ListItem>

        ))}
      </List>
    </Box>
  );
};

export default ContentList;
