import React from 'react';
import { Box, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { LocalFireDepartment, Update, Subscriptions,Category } from '@mui/icons-material';

import { useDrama } from '../contexts/DramaContext';

const LeftSidebar = () => {

  const { selectedItem, setSelectedItem} = useDrama();

  const menuItems = [
    { icon: <LocalFireDepartment />, label: 'Hot' },
    { icon: <Update />, label: 'Updates' },
    { icon: <Category />, label: 'Category' },
    { icon: <Subscriptions />, label: 'Subscriptions' }
  ];

  return (
    <Box sx={{marginTop:2}}>
      <MenuList sx={{ paddingTop: 0 }}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => setSelectedItem(item.label)}
            sx={{
              backgroundColor: selectedItem === item.label ? '#2b2b2b' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              borderRadius: 1,
              marginLeft:1
            }}
          >
            <ListItemIcon>
              <IconButton size='large' sx={{color: 'white' }}>
                {React.cloneElement(item.icon)}
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{fontWeight: 'bold', color: 'white' }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default LeftSidebar;
