import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Fab, Card, CardContent, Typography, IconButton, Divider, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Grid2 } from '@mui/material';
import { ThumbUp, ChevronLeft, ChevronRight, WatchLater, KeyboardDoubleArrowUp, KeyboardDoubleArrowDown } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import VideoPlayer from './VideoPlayer';
import styled from '@emotion/styled';
import Cookies from 'js-cookie';
import { useDrama } from '../contexts/DramaContext';
import { LocalStorageContext } from '../utils/localStorage'

// 样式组件
const VideoContainer = styled(Card, {
    shouldForwardProp: (prop) => !['isExpanded', 'slideDirection'].includes(prop)
})(({ isExpanded, slideDirection }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: 'auto',
    width: isExpanded ? '100%' : '80%',
    transition: 'width 0.3s',
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 2,
    backgroundColor: "#2b2b2b",
    borderRadius: 4,
    animation: slideDirection &&
        `slide${slideDirection.charAt(0).toUpperCase() + slideDirection.slice(1)} 0.5s ease-in-out`,
    '@keyframes slideUp': {
        '0%': { transform: 'translateY(100%)' },
        '100%': { transform: 'translateY(0)' }
    },
    '@keyframes slideDown': {
        '0%': { transform: 'translateY(-100%)' },
        '100%': { transform: 'translateY(0)' }
    }
}));

const Sidebar = styled(Box)({
    width: '18%',
    marginLeft: 8,
    marginRight: 4,
    marginTop: 4,
    marginBottom: 2,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto'
});

const FloatingButtons = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
    gap: '16px',
});

const VideoContent = ({ viewModel }) => {
    const {
        comments,
        watchLater,
        fetchDramas,
        fetchEpisodes,
        fetchEpisodeDetail,
        fetchComments,
    } = viewModel;

    const navigate = useNavigate();
    const location = useLocation();

    const replaceDramaNameInPath = (newDramaId) => {
        // const pathParts = location.pathname.split('/');
        // pathParts[pathParts.length - 1] = newDramaId; // 替换最后一部分
        // const newPath = pathParts.join('/');
        // navigate(newPath, { replace: true });
    };

    const pageSize = 4
    const { currentDramaId,
        setShowPaymentDialog,
        currentPage, setCurrentPage,
        currentPlayTime,
        diableFetchDrama, setDiableFetchDrama} = useDrama();

    const [dramas, setDramas] = useState([])
    const [currentEpisodes, setCurrentEpisodes] = useState([])
    const [currentEpisodeDetail, setCurrentEpisodeDetail] = useState([])
    const [episodes, setEpisodes] = useState([])
    const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState(null);

    // 自定义更新currentEpisode状态的函数
    const _setCurrentEpisodeDetail = (episodeDetail) => {
        if (episodeDetail && !episodeDetail.dramaUrl) {
            console.log("need payment")
            //setShowPaymentDialog(true)
            return
        }
        episodeDetail['imgUrl'] = currentEpisodes.imgUrl
        setCurrentEpisodeDetail(episodeDetail)
    };

    const listDramas = async (pageNo) => {
        console.log("listDramas",pageNo)
        const dramaData = await fetchDramas(pageNo, pageSize)
        setDramas(dramaData)
        if (dramaData && dramaData.data && dramaData.data.length > 0) {
            const drama = dramaData.data[0]
            const episodesData = await fetchEpisodes(drama.dramaId)
            setCurrentEpisodes(episodesData)
            if (episodesData && episodesData.dramaDetails && episodesData.dramaDetails.length > 0) {
                const episode = episodesData.dramaDetails[0]
                const detail = await fetchEpisodeDetail(episode.id)
                if (detail) {
                    _setCurrentEpisodeDetail(detail)
                }
            }
        }
    }

    const switchCurrentDramas = async (index) => {
        console.log("switchCurrentDramas", index)
        if (dramas && dramas.data && dramas.data.length > 0) {
            const drama = dramas.data[index]
            const episodesData = await fetchEpisodes(drama.dramaId)
            setCurrentEpisodes(episodesData)
            if (episodesData && episodesData.dramaDetails && episodesData.dramaDetails.length > 0) {
                const episode = episodesData.dramaDetails[0]
                const detail = await fetchEpisodeDetail(episode.id)
                if (detail) {
                    _setCurrentEpisodeDetail(detail)
                }
            }
        }
    }

    const selectEpisode = async (index) => {
        console.log("selectEpisode", index)
        if (currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.length > 0) {
            const episode = currentEpisodes.dramaDetails[index]
            const detail = await fetchEpisodeDetail(episode.id)
            if (detail) {
                _setCurrentEpisodeDetail(detail)
            }
        }
    }

    const videoContainerRef = useRef(null);

    useEffect(() => {
        console.log("dramaid jump", currentDramaId)
        if (currentDramaId) {
            // 定义一个立即执行的异步函数
            (async () => {
                const episodes = await fetchEpisodes(currentDramaId);
                setCurrentEpisodes(episodes);
                if (episodes && episodes.dramaDetails && episodes.dramaDetails.length > 0) {
                    const episode = episodes.dramaDetails[0];
                    const detail = await fetchEpisodeDetail(episode.id);
                    if (detail) {
                        _setCurrentEpisodeDetail(detail);
                    }
                }
            })();
        }
    }, [currentDramaId]);

    useEffect(() => {
        if (slideDirection) {
            const timer = setTimeout(() => {
                setSlideDirection(null);
            }, 500); // 动画持续时间
            return () => clearTimeout(timer);
        }
    }, [slideDirection]);


    useEffect(() => {
        if (diableFetchDrama) {
            setDiableFetchDrama(false)
            return;
        }
        console.log("init render")
        listDramas(1)
    }, []);


    const isLogin = () => {
        return Cookies.get('sessionId') !== undefined
    }

    const onWatchLater = () => {
        if (isLogin()) {
            watchLater(currentEpisodes.dramaId, currentEpisodes.id, currentPlayTime)
        } else {
            const ep_info = currentEpisodes
            ep_info['playTime'] = currentPlayTime
            LocalStorageContext.pushItem("watchLater", ep_info)
        }
    }

    const toggleExpand = () => setIsExpanded(() => {
        return !isExpanded
    });

    const playEpisode = (index) => {
        setCurrentEpisodeIndex(index)
        selectEpisode(index)
    };

    const nextPage = () => {
        const page = currentPage + 1
        setCurrentPage(page)
        listDramas(page)
    }

    const prevPage = () => {
        const page = Math.max(currentPage - 1, 1);
        setCurrentPage(page)
        listDramas(page)
    }

    const handleSwipe = (eventData) => {
        const { dir } = eventData;
        if (dir === 'Up') {
            setSlideDirection('up');
            if (currentScrollIndex === pageSize - 1) {
                nextPage()
                setCurrentScrollIndex(0);
            } else {
                setCurrentScrollIndex((prevIndex) => prevIndex + 1);
                switchCurrentDramas(currentScrollIndex)
            }
        } else if (dir === 'Down') {
            setSlideDirection('down');
            if (currentScrollIndex === 0) {
                prevPage()
                setCurrentScrollIndex(pageSize - 1);
            } else {
                setCurrentScrollIndex((prevIndex) => prevIndex - 1);
                switchCurrentDramas(currentScrollIndex)
            }
        }
    };

    const handlers = useSwipeable({
        onSwipedUp: (eventData) => handleSwipe({ dir: 'Up' }),
        onSwipedDown: (eventData) => handleSwipe({ dir: 'Down' }),
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: true,
        delta: 10
    });

    return (
        <Box sx={{ display: 'flex', height: 'auto', width: '100%' }}>
            <VideoContainer isExpanded={isExpanded} slideDirection={slideDirection} {...handlers} ref={videoContainerRef}>
                {currentEpisodeDetail && currentEpisodeDetail.dramaUrl && currentEpisodeDetail.subtitles && (<VideoPlayer viewModel={viewModel} currentEpisode={currentEpisodeDetail} ratio={9 / 16} />)}
                <FloatingButtons>
                    <Fab color="primary" aria-label="like" size='medium'><ThumbUp /></Fab>
                    <Fab color="secondary" aria-label="toggle-expand" size='medium' onClick={toggleExpand}>
                        {isExpanded ? <ChevronRight size='medium' /> : <ChevronLeft size='medium' />}
                    </Fab>
                    <Fab color="default" aria-label="watch-later" size='medium'>
                        <WatchLater onClick={onWatchLater} />
                    </Fab>
                </FloatingButtons>
            </VideoContainer>
            {isExpanded && (
                <Sidebar sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card sx={{ maxHeight: '70%', overflow: 'hidden', display: 'flex', flexDirection: 'column', backgroundColor: "#2b2b2b" }}>
                        {currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.length > 0 && (
                            <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6" sx={{ display: 'flex', color: 'white' }}>{currentEpisodes.name}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{
                                        flexBasis: '36%',  // 图片占据30%的宽度
                                        flexShrink: 0
                                    }}>
                                        <img src={currentEpisodes.imgUrl} alt="drama thumbnail" style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'  // 保持图片比例并填充容器
                                        }} />
                                    </Box>
                                    <Box sx={{
                                        flexGrow: 1,  // 文字部分占据剩余空间
                                        overflow: 'hidden'
                                    }}>
                                        <Typography variant="body2" sx={{
                                            color: 'white',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 5,  // 限制最多显示5行
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            paddingLeft: 2
                                        }}>
                                            {currentEpisodes.desc}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
                                <Typography variant="h6" sx={{ color: 'white' }}>Episode List</Typography>
                                <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                                    <Grid2 container spacing={1}>
                                        {currentEpisodes && currentEpisodes.dramaDetails && currentEpisodes.dramaDetails.map((item, index) => (
                                            <Grid2 item key={index}>
                                                <Button
                                                    size="small"
                                                    variant={currentEpisodeIndex === index ? "contained" : "outlined"}
                                                    onClick={() => playEpisode(index)}>
                                                    {item.order}
                                                </Button>
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                </Box>
                            </CardContent>
                        )}
                    </Card>

                    <Card sx={{ maxHeight: '30%', marginTop: 2, display: 'flex', flexDirection: 'column', backgroundColor: "#2b2b2b" }}>
                        <CardContent sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" sx={{ color: 'white' }}>Comments</Typography>
                            <Divider />
                            <List sx={{ overflow: 'auto', flexGrow: 1 }}>
                                {comments && comments.map((comment, index) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar>{comment.avatar}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            sx={{ color: 'white' }}
                                            primary={comment.content}
                                            secondary={
                                                <Typography variant="caption" color="rgba(255,255,255,0.4)">
                                                    {comment.time}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Sidebar>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "#1c1c1c" }}>
                <IconButton size='small'>
                    <KeyboardDoubleArrowUp sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </IconButton>
                <IconButton size='small'>
                    <KeyboardDoubleArrowDown sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default VideoContent;
