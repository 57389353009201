import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, Chip, Card, CardMedia, Typography, CardContent, IconButton, CircularProgress } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import styled from '@emotion/styled';
import { useDrama } from '../contexts/DramaContext';

const tags = ["All", "Ancient Style", "Fantasy", "Rebirth", "Superpower", "Bossy Hero", "Palace", "Love Story", "Modern Romance", "Flash Marriage", "Sweet Love", "Time Travel", "City Life", "Counterattack", "God of War", "Revenge", "Vintage Clothing", "Pure Love", "Daily Life", "For Girls", "For Boys", "For Elderly", "Funny", "Inspiring", "Positive Energy", "Sci-Fi Myth", "Youth Idol", "Military War", "Mystery Crime", "Fantasy Adventure", "Criminal Investigation", "Comedy", "Family", "Horror", "Republic of China Era", "Campus", "Love Skill", "Romance", "Gastronomy", "Miracle Doctor", "Passionate", "Cool Drama"]

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'scroll', // 取消滑动交互
    scrollBehavior: 'smooth', // 滑动行为设为平滑
    width: '100%',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
        display: 'none', // 隐藏滚动条
    },
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    msOverflowStyle: 'none',  // IE和Edge
    scrollbarWidth: 'none',
    margin: 1
});

const CategoryContent = ({ viewModel }) => {

    const heights = [390, 410, 340, 360, 430];
    const aspectRatio = 9 / 16; // 宽高比例

    const [selectedTag, setSelectedTag] = useState(tags[0]);
    const [items, setItems] = useState([]);
    const {
        fetchDramas,
    } = viewModel;
    const [page, setPage] = useState(1);
    const [hitBottom, setHitBottom] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const lastElementRef = useRef(null);

    useEffect(() => {
        (async () => {
            const results = await fetchDramas(page, 20);
            if (results && results.data && results.data.length > 0) {
                setItems(results.data);
            }
        })();
    }, []);

    const handleSelectTag = async (tag) => {
        console.log(tag);
        setSelectedTag(tag);
        setPage(1);
        setItems([]);
        setHitBottom(false);
        const reqtTag = tag === 'All' ? null : tag;
        const results = await fetchDramas(1, 20,null, reqtTag);
        console.log(results);
        if (results && results.data && results.data.length > 0) {
            setItems(results.data);
        }
    };

    const handleLoadMore = async () => {
        if (isLoading || hitBottom) return;
        setIsLoading(true);
        const nextPage = page + 1;
        console.log(nextPage);
        const reqtTag = selectedTag === 'All' ? null : selectedTag;
        const results = await fetchDramas(nextPage, 20, null, reqtTag);
        if (results && results.data && results.data.length > 0) {
            setItems(prevItems => [...prevItems, ...results.data]);
            setPage(nextPage);
        } else {
            setHitBottom(true);
        }
        setIsLoading(false);
    };

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            handleLoadMore();
        }
    }, [handleLoadMore]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (lastElementRef.current) observer.observe(lastElementRef.current);
        return () => {
            if (lastElementRef.current) observer.unobserve(lastElementRef.current);
        };
    }, [handleObserver]);

    const { setCurrentDramaId, setSelectedItem, setDiableFetchDrama } = useDrama();


    const handleWatchNow = (id) => {
        setCurrentDramaId(id)
        setDiableFetchDrama(true)
        setSelectedItem('Hot')
    }

    return (
        <Box sx={{ display: 'flex', height: 'auto', width: '100%', flexDirection: 'column', margin: 2, overflow: 'hidden' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2, marginBottom: 3 }}>
            {tags.map((tag) => (
                <Chip
                    key={tag}
                    label={tag}
                    onClick={() => handleSelectTag(tag)}
                    size='large'
                    color={selectedTag === tag ? 'primary' : 'secondary'}
                />
            ))}
        </Box>
            <ScrollContainer>
                <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={2}>
                    {items.map((item, index) => {
                        const height = heights[index % heights.length];
                        return (<Box key={index} sx={{ height: height }}>
                            <Card sx={{ width: '100%', height: '100%', backgroundColor: '#1c1c1c', position: 'relative' }}>
                                <CardMedia component="img" sx={{
                                    width: '100%',
                                    height: '92%',
                                    objectFit: 'cover'
                                }} image={item.imgUrl} />
                                <CardContent sx={{
                                    height: '8%', padding: 0, display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="caption" sx={{
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        color: 'white',
                                        margin: '0 auto'
                                    }}>{item.name}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                                    <IconButton size='small' sx={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                                        value={item.dramaId}
                                        onClick={(e) => handleWatchNow(item.dramaId)}
                                    >
                                        <PlayArrow fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Card>
                        </Box>
                        );
                    })}
                </Masonry>
                <Box
                    ref={lastElementRef}
                    sx={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    {hitBottom && (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 1 }}>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Box sx={{ width: '60px', height: '1px', backgroundColor: 'white' }} />
                            </Box>
                            <Typography variant="body1" color='white' sx={{ padding: '0 10px' }}>
                                No more content
                            </Typography>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                                <Box sx={{ width: '60px', height: '1px', backgroundColor: 'white' }} />
                            </Box>
                        </Box>
                    )}
                </Box>

            </ScrollContainer>
        </Box>
    );
};

export default CategoryContent;
